import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const CarbonCredits = () => {
    return (
        <Layout>
            <Seo title="Carbon Credits" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Carbon Credits</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/ice.jpg"
                                    aspectRatio={16 / 7}
                                    width={696}
                                    layout="fixed"
                                />
                            </div>
                            <div className="col-lg-6 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <p>
                                        Delta Corp Energy Logistics are certified members of the carbon standard programs VERRA and
                                        Goldstandard. These alliances set the standard and facilitate the transparent listing of
                                        information on certified projects. A proud certified member allows Delta Corp to facilitate and
                                        offer an additional service to their clients to offset their CO2 emissions on a voluntary
                                        basis.
                                    </p>
                                    <p>
                                        Being part of the VERRA and Goldstandard programs ensures Delta remains compliant and only
                                        offering the very best economical, environmentally friendly and renewable projects to their
                                        clients to ensure the pathway to a sustainable future and this can be done for a single
                                        bunkering of HSFO/VLSFO/DMA 0.1% or for a specific vessel voyage or the vessels yearly CO2
                                        emission output.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <p>
                            Delta regularly offset their own greenhouse gasses from their bunker barge’s resulting from the combustion
                            and are operating in the ARA and UK regions to reduce their own carbon footprint. A range of global
                            projects are available that not only reduce greenhouse gas emissions but also provide the wider
                            additionality of social, economic and health benefits to some of the poorest areas of the planet.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <StaticImage src="../../../images/other/verra.png" width={250} alt="ISCC certified" placeholder="tracedSVG" />
                    </div>
                    <div className="col d-flex flex-wrap align-items-center">
                        <StaticImage
                            src="../../../images/other/gold-standard.png"
                            width={250}
                            alt="ISCC certified"
                            placeholder="tracedSVG"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CarbonCredits
